.logo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    .logo-content img {
    width: 15%;
    margin: 0 auto;
    }
    
.caption {
margin-top: 20px;
font-size: 20px;
}