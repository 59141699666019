.title-about {
    text-align: center;
    margin-top: 20px;
    }
    
.about-description {
    font-size: 20px;
    margin: 20px;
    text-align: block;
    font-weight: normal;
    }