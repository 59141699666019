.title-pp {
    text-align: center;
    margin-top: 20px;
    }

.subheading.title-pp {
    font-size: 20px;
    margin: 20px;
    text-align: left;
    display: block;
    font-weight: bold;
} 

p.title-pp {
    font-size: 20px;
    margin: 20px;
    text-align: left;
    display: block;
    font-weight: normal;
    }

